import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "./../../environments/environment";
import { ProspectService } from "./prospect-service.service";
import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { map, shareReplay } from "rxjs/operators";
import brandInfo from "../../environments/brand.json";

import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from "@angular/router";
import { env } from "process";
import { Vehicle } from "../_models/vehicle";
import { Store } from "@ngrx/store";
import Cookies from "js-cookie";
import { EMPTY,  lastValueFrom, from, Observable, of } from "rxjs";
import { Product, ProductDisclaimers } from "../_models/product";
import { loadProductDisclaimers } from "../_actions/product-disclaimers.actions";
import Prismic from "prismic-javascript";
import { LocalStorage } from "@ng-idle/core";
import { prismicContent } from "../_models/prismic";
import { Lead } from "../_models/lead";
import { RatesResponse } from "../_models/rates";

import { previewCart } from "../_data/cart";
import { previewLead } from "../_data/lead";
import { previewOpp } from "../_data/opp";
import { previewPlan } from "../_data/plan";
import { previewPrismicPdf } from "../_data/prismicPdf";
import { previewProductDisclaimers } from "../_data/productDisclaimers";
import { previewRates } from "../_data/rates";
import { previewUrlParams } from "../_data/urlParams";
import { previewVehicle } from "../_data/vehicle";
import { previewConfirmation } from "../_data/confirmation";
import { previewEmailInfo } from "../_data/emailInfo";

import { PrismicService } from "./prismic.service";
import { showInlineLoader } from "../_actions/inline-loader.actions";
import { rehydrateVehicle } from "../_actions/vehicle.actions";
import { rehydrateRates } from "../_actions/rates.actions";
import { rehydrateCart } from "../_actions/cart.actions";
import { rehydrateEmail } from "../_actions/email.actions";
import { saveUrlParams } from "../_actions/urlParams.actions";
import { loadConfirmationNumber } from "../_actions/confirmation.actions";
import { rehydrateLead } from "../_actions/lead.actions";
import { loadOpportunity } from "../_actions/opportunity.actions";
import { rehydratePlan } from "../_actions/plan.actions";

import { retry, catchError } from "rxjs/operators";
import { MDBModalService } from "ng-uikit-pro-standard";
import { ModalTechnicalDifficultyComponent } from "../modal-technical-difficulty-error/modal-technical-difficulty-error.component";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  vehicle$: Observable<Vehicle>;
  footerCache = null;

  constructor(
    private modalService: MDBModalService,
    private apollo: Apollo,
    private prospectService: ProspectService,
    private httpClient: HttpClient,
    private prismicService: PrismicService,
    private route: ActivatedRoute,
    private store: Store<{
      vehicle: Vehicle;
    }>
  ) {
    this.modalService = modalService;
  }

  createSlices(typesArray, prismicData, uniqueSlicesArray) {
    const slices = [];
    console.log("e. createSlices from orderData: ", { typesArray, prismicData, uniqueSlicesArray });

    for (let i = 0; i < typesArray.length; i++) {
      const element = typesArray[i];

      // Get index of the prismic data
      const queryIndex = uniqueSlicesArray.indexOf(typesArray[i]);
      if(prismicData[queryIndex].data.page) {
        slices.push(prismicData[queryIndex].data.page.body[i]);
      }
    }

    return slices;
  }

  async getLeadInformation(brand, prid) {
    const leadRequestData = {
      payload: {
        prid: prid,
        brand: brand,
      },
    };
    let leadResponse;

    try {
      leadResponse = await  lastValueFrom(this.prospectService.get_accountContact(leadRequestData));
    } catch (err) {
      console.error("getLeadInformation Error: ", err);
      leadResponse = {
        vehicle: {
          isElectric: false,
        },
      };
    }

    return leadResponse;
  }

  async getTags(tags: Array<string>, route) {
    console.log("Getting tags.", tags);

    let vehicle;

    // Get Vehicle From Store
    // let vehicle = await this.store.select((store) => store.vehicle).toPromise()

    // If vehicle does not exist in store, try to get from Session Storage.
    if (!vehicle) {
      vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
      console.log("Getting vehicle from Session Storage...", vehicle);
    } else {
      console.log("Got vehicle from store");
    }

    // If vehicle does not exists in store or session storage, try to get from Account api.
    if (!vehicle) {
      if (route.queryParams.prid) {
        let leadResponse = await this.getLeadInformation(brandInfo.brand, route.queryParams.prid);
        vehicle = leadResponse.vehicle;
        console.log("Getting vehicle from API...", vehicle);
      }
    }

    if (vehicle) {
      console.log("vehicle: ", vehicle);

      tags = vehicle.isElectric ? [...tags, "electric"] : [...tags, "non-electric"];
      console.log("tags: ", tags);
    } else {
      // This should only be if no vehicle.
      console.log("This should only be called if no vehicle object in store, session storage, or from account api. Defaulting to non-electric. ");
      tags = [...tags];
    }

    return tags;
  }

  async resolvePage(route: ActivatedRouteSnapshot) {
    // Get query Params
    // const brand: string = route.queryParams.brand || "REV";

    console.log("Route info: ", route);

    const pageType: string = route.data.pageType ? route.data.pageType : "home";
    const lang: string = route.data.lang ? route.data.lang : "en-us";
    const productType: string = route.data.productType ? route.data.productType : "";
    let tags = await this.getTags([productType, pageType, brandInfo.brand], route);

    return this.queryAll(tags, lang);
  }

  getPage(params) {
    console.log("%c ***** home.service.ts ******", "color: #188977");

    console.log("a. getPage: ", params);

    let lang = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;

    if (params.pageId) {
      console.log("b. Get prismic page by page id");
      return this.getPageById(params.pageId, lang);
    } else if (params.pageurl) {
      // Check for electric
      let pageurl = params.pageurl;

      if (params.isElectric == "electric" && (params.pageurl == "vehicle-service-protection" || params.pageurl == "vsc")) {
        pageurl = "electric-vehicle-service-protection";
      }
      console.log("b. Get prismic page by page id");
      return this.getPageByUrl(pageurl, params.parenturl, brandInfo.brand, lang);
    } else {
      console.log("b. Get prismic page by tags");
      return this.getPageByTags(params);
    }
  }

  getPageByTags(params) {
    console.log("getPageByTags: ", params);

    const pageType: string = params.pageType ? params.pageType : "home";
    const lang: string = params.lang ? params.lang : "en-us";
    const product: string = params.product ? params.product : "";
    let tags = [product, pageType, brandInfo.brand, params.isElectric];
    tags = tags.filter((tag) => tag !== "");
    let content = from(this.queryAll(tags, lang));
    console.log("%c ***********", "color: #188977");
    return content;
  }

  getPageById(pageId: string, lang: string) {
    console.log("getPageById: ", pageId);
    let content = from(this.queryAllByPageId(pageId, lang));
    // let content = from(this.getPageByUid(pageId, lang));

    console.log("%c ***********", "color: #188977", content);
    return content;
  }

  getPageByUrl(pageurl: string, parenturl: string, brand: string, lang: string) {
    console.log("getPageByUrl: ", parenturl, pageurl);
    let content = from(this.queryAllByPageUrl(pageurl, parenturl, brand, lang));
    // let content = from(this.getPageByUid(pageId, lang));

    console.log("%c ***********", "color: #188977", content);
    return content;
  }

  async queryAllByPageUrl(pageurl: string, parenturl: string, brand: string, lang: string) {
    console.log(`Querying Prismic slices based on url ${pageurl}`);

    const orderData = await this.getOrderByPageUrl(pageurl, parenturl, brand, lang);

    // If No page exists, gracefully exit
    if (!orderData) {
      console.log(`No Prismic data found that matches pageurl ${pageurl}`);
      return [];
    }

    console.log(`orderData:`, orderData);

    return this.getSlices(orderData, lang);
  }

  async queryAllByPageId(pageId: string, lang: string) {
    console.log(`c. Querying Prismic slices based on id ${pageId}`);

    const orderData = await this.getOrderByPageId(pageId, lang);

    // If No page exists, gracefully exit
    if (!orderData) {
      console.log(`d. No Prismic data found that matches pageId ${pageId}`);
      return [];
    }

    console.log(`d. orderData:`, orderData);

    return this.getSlices(orderData, lang);
  }

  async queryAll(tags: string[], lang: string) {
    console.log(tags);
    console.log(`Querying Prismic slices based on tags ${tags}`);

    const orderData = await this.getOrderByTag(tags, lang);

    // If No page exists, gracefully exit
    if (!orderData) {
      console.log(`No Prismic data found that matches tag ${tags}`);
      return [];
    }

    return this.getSlices(orderData, lang);
  }

  async getSlices(orderData, lang) {
    // Get set of group request
    const prismicSlices = orderData.body;
    const uid = orderData._meta.uid;

    // Get page meta data
    const pageMeta = {
      meta_title: orderData.meta_title,
      meta_description: orderData.meta_description,
      robots: orderData.robots,
    };

    // Get class name assigned to page
    const pageClass = orderData.class_name;

    // console.log("pageMeta: ", pageMeta)

    // get distinct slices
    const typesArray = prismicSlices.filter((element) => !!element.type).map((element) => element.type);
    const uniqueSlicesArray = [...new Set(typesArray)];

    console.log(`Querying slices content for ${uid}`);

    // Create Array of promises
    const promises = [];
    for (const type of uniqueSlicesArray) {
      switch (type) {
        // case "header":
        //   promises.push(this.getHeader(uid, lang));
        //   break;
        case "hero":
          promises.push(this.getHero(uid, lang));
          break;
        case "content_block":
          promises.push(this.getContentBlocks(uid, lang));
          break;
        case "plans":
          promises.push(this.getPlans(uid, lang));
          break;
        case "faq":
          promises.push(this.getFAQ(uid, lang));
          break;
        case "seo":
          promises.push(this.getSEO(uid, lang));
          break;
        case "product":
          promises.push(this.getProduct(uid, lang));
          break;
        case "products_tabset":
          promises.push(this.getProductTabset(uid, lang));
          break;
        case "title":
          promises.push(this.getTitle(uid, lang));
          break;
        case "text":
          promises.push(this.getText(uid, lang));
          break;
        case "image":
          promises.push(this.getImage(uid, lang));
          break;
        case "support_page":
          promises.push(this.getSupportPage(uid, lang));
          break;
        default:
          break;
      }
    }

    // Perform all promises at once
    const prismicData = await Promise.all(promises);

    const slices = this.createSlices(typesArray, prismicData, uniqueSlicesArray);
    // console.log("queryAll - Got slices: ", slices);

    let pageData: prismicContent = { content: slices, pageMeta: pageMeta, pageClass: pageClass };

    return pageData;
  }

  getBranding(brand, lang) {
    const headers = this.getPrismicRefHeaders();

    return this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/brands`, {
        params: {
          brand: brand,
          lang: lang
        },
        headers: headers,
      })
      .pipe(
        catchError((error) => {
          this.modalService.show(ModalTechnicalDifficultyComponent, { class: "modal-dialog-centered" });
          return error;
        }),
        map((result) => {
          // console.log("getBranding: ", result.data.allBrandings.edges[0].node);
          console.log('result.data.allBrandings.edges[0]: ',result.data.allBrandings.edges[0])
          if (result.data.allBrandings.edges[0] !== undefined) {
            return result.data.allBrandings.edges[0].node;
          } else {
            return this.getBackupBranding(brand);
          }
        })
      );
  }

  getBackupBranding(brand) {
    const headers = this.getPrismicRefHeaders();
    return this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/brands`, {
        params: {
          brand: brand,
          lang: 'en-us' // Load 'en-us' in case the registered language isn't accounted for
        },
        headers: headers,
      })
      .pipe(
        catchError((error) => {
          this.modalService.show(ModalTechnicalDifficultyComponent, { class: "modal-dialog-centered" });
          return error;
        }),
        map((result) => {
          // console.log("getBranding: ", result.data.allBrandings.edges[0].node);
          console.log('result.data.allBrandings.edges[0]: ',result.data.allBrandings.edges[0])
          return result.data.allBrandings.edges[0].node;
        })
      );
  }

  getOrderByPageUrl(pageurl: string, parenturl: string, brand: string, lang: string): any {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/page/orderByPageUrl`, {
        params: {
          pageurl,
          parenturl: parenturl ? parenturl : "",
          brand,
          lang: lang,
        },
        headers: headers,
      }))
  }

  getOrderByUid(uid: string, lang: string): any {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/page/orderByUid`, {
        params: {
          uid: uid,
          lang: lang,
        },
        headers: headers,
      }))
  }

  getOrderByTag(tags: string[], lang: string): any {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/page/orderByTag`, {
        params: {
          tags: tags,
          lang: lang,
        },
        headers: headers,
      }));
  }

  checkForPreviewMode() {
    this.route.queryParams.subscribe((params) => {
      this.store.dispatch(showInlineLoader());

      if (params["preview"]) {
        console.log("%c ***** Entering Preview Mode ******", "color: #1ECBE1");
        this.store.dispatch(showInlineLoader());

        this.loadPreviewMode();
      }
    });
  }

  loadPreviewMode() {
    // If preview mode
    let _ratesSession: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));
    let isPreviewMode = this.prismicService.isPreviewMode();
    let _prismicRef = localStorage.getItem("Prismic-ref");
    let _vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    let _lead: Lead = JSON.parse(sessionStorage.getItem("lead"));
    let _opp = JSON.parse(sessionStorage.getItem("opp"));
    let _cart = JSON.parse(sessionStorage.getItem("cart"));
    let _prismicPdf = JSON.parse(sessionStorage.getItem("prismicPdf"));
    let _urlParams = JSON.parse(sessionStorage.getItem("prismicPdf"));
    let _plan = JSON.parse(sessionStorage.getItem("urlParams"));
    let _productDisclaimers = JSON.parse(sessionStorage.getItem("productDisclaimers"));
    let _confirmation = JSON.parse(sessionStorage.getItem("confirmationNumber"));
    let _email = JSON.parse(sessionStorage.getItem("email"));

    console.log("%c Checking for Prismic Preview Mode Data:", "color: #1ECBE1", isPreviewMode, _prismicRef);

    // Set Dummy Data

    if (!_cart) {
      this.store.dispatch(rehydrateCart(previewCart));
    }

    if (!_lead) {
      this.store.dispatch(rehydrateLead(previewLead));
    }
    if (!_opp) {
      this.store.dispatch(loadOpportunity(previewOpp));
    }

    if (!_plan) {
      this.store.dispatch(rehydratePlan(previewPlan));
    }

    if (!_prismicPdf) {
      window.sessionStorage.setItem("prismicPdf", JSON.stringify(previewPrismicPdf));
    }

    if (!_productDisclaimers) {
      this.store.dispatch(loadProductDisclaimers(previewProductDisclaimers));
    }

    if (!_ratesSession) {
      this.store.dispatch(rehydrateRates(previewRates));
    }

    if (!_urlParams) {
      this.store.dispatch(saveUrlParams(previewUrlParams));
    }

    if (!_vehicle) {
      this.store.dispatch(rehydrateVehicle(previewVehicle));
    }

    if (!_confirmation) {
      this.store.dispatch(loadConfirmationNumber(previewConfirmation));
    }

    if (!_email) {
      this.store.dispatch(rehydrateEmail(previewEmailInfo));
    }
  }

  getOrderByPageId(pageId: string, lang: string): any {
    console.log("getOrderByPageId");
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/page/orderByPageId`, {
        params: {
          pageId: pageId,
          lang: lang,
        },
        headers: headers,
      }))
  }

  getHeader(brand: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/header`, {
        params: {
          brand: brand,
          lang: lang,
        },
        headers: headers,
      })
      .pipe(map((result) => result.data.allHeaders.edges[0].node));
  }

  getFooter(brand: string, lang: string): Observable<any> {
    if(this.footerCache) {
      return this.footerCache;
    } else {
    const headers = this.getPrismicRefHeaders();
    return this.footerCache = this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/footer`, {
        params: {
          brand: brand,
          lang: lang,
        },
        headers: headers,
      })
      .pipe(
      shareReplay(1),
      map((result) => result.data.allFooters.edges[0].node),
      catchError(err => {
          this.footerCache = null;
          return EMPTY;
      }));
    }
  }

  getHero(uid: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/slice/hero`, {
        params: {
          uid: uid,
          lang: lang,
        },
        headers: headers,
      }))
  }

  getProduct(uid: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/slice/product`, {
        params: {
          uid: uid,
          lang: lang,
        },
        headers: headers,
      }));
  }

  getProductTabset(uid: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/slice/producttabs`, {
        params: {
          uid: uid,
          lang: lang,
        },
        headers: headers,
      }))
  }

  getPlans(uid: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/slice/plans`, {
        params: {
          uid: uid,
          lang: lang,
        },
        headers: headers,
      }))
  }

  getTitle(uid: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/slice/title`, {
        params: {
          uid: uid,
          lang: lang,
        },
        headers: headers,
      }))
  }

  getText(uid: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/slice/text`, {
        params: {
          uid: uid,
          lang: lang,
        },
        headers: headers,
      }))
  }

  getImage(uid: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/slice/image`, {
        params: {
          uid: uid,
          lang: lang,
        },
        headers: headers,
      }))
  }

  getFAQ(uid: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/slice/faq`, {
        params: {
          uid: uid,
          lang: lang,
        },
        headers: headers,
      }))
  }

  getPageByUid(uid: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/page`, {
        params: {
          uid: uid,
          lang: lang,
        },
        headers: headers,
      }))
  }

  async getMasterToken() {
    const env = environment.production ? "prod" : "dev";
    const prismicEnv = await this.prismicService.getEnvironment(env);

    console.log("prismicEnv 3: ", prismicEnv);

    let apiData = await Prismic.api(prismicEnv.apiEndpoint, {
      accessToken: prismicEnv.accessToken,
    });
    const masterRef = apiData.refs.find((ref) => ref.isMasterRef === true);

    return masterRef.ref;
  }

  getPrismicRefHeaders() {

    const env = environment.production ? "prod" : "dev";
    let prismicEnv = this.prismicService.getEnvironment(env);

    // console.log("prismicEnv: ", prismicEnv);

    // Get Cookies - Created in PrismicService
    let prismicRef = localStorage.getItem("Prismic-ref") || "";
    let prismicBaseUrl = localStorage.getItem("Prismic-baseUrl") || prismicEnv.baseUrl;

    // Set Headers
    const httpHeaders = new HttpHeaders({
      "Prismic-ref": prismicRef,
      "Prismic-baseUrl": prismicBaseUrl,
    });

    return httpHeaders;
  }

  getContentBlocks(uid: string, lang: string) {
    // console.log("getContentBlocks", uid, lang);

    const headers = this.getPrismicRefHeaders();

    console.log("Prismic headers", headers);

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/contentblock`, {
        params: {
          uid: uid,
          lang: lang,
        },
        headers: headers,
      }))
  }

  getContentBlockByUid(uid: string) {
    console.log("getContentBlock By Id", uid);

    const headers = this.getPrismicRefHeaders();
    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/slice/contentblock/${uid}`, {
        headers: headers,
      }))
  }

  getSEO(uid: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/seo`, {
        params: {
          uid: uid,
          lang: lang,
        },
        headers: headers,
      }))
  }

  findPageAdvanced(searchText: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return this.httpClient.get<any>(`${environment.apiUrl}/v1/api/content/support-page/findAdvanced`, {
      params: {
        searchText: searchText,
        lang: lang,
      },
      headers: headers,
    });
  }

  findPage(searchText: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return  lastValueFrom(this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/support-page/findPage`, {
        params: {
          searchText: searchText,
          lang: lang,
        },
        headers: headers,
      }))
  }

  getAllSupportPages(lang: string) {
    const headers = this.getPrismicRefHeaders();

    return this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/content/support-page/all`, {
        params: {
          lang: lang,
        },
        headers: headers,
      })
      .pipe(map((result) => result.data));
  }

  getSupportPage(tags: string[], lang: string) {
    const headers = this.getPrismicRefHeaders();

    return this.httpClient.get<any>(`${environment.apiUrl}/v1/api/content/support-page`, {
      params: {
        tags: tags,
        lang: lang,
      },
      headers: headers,
    });
  }

  getSupportPageByUid(uid: string, lang: string) {
    const headers = this.getPrismicRefHeaders();

    return this.httpClient.get<any>(`${environment.apiUrl}/v1/api/content/support-page/${uid}`, {
      params: {
        lang: lang,
      },
      headers: headers,
    });
  }
}
