export const environment = {
  production: false,
  baseUrl: 'https://d2c-gateway-staging.herokuapp.com/dtc-gateway-srvc/',
  // baseUrl: 'http://localhost:3000/dtc-gateway-srvc/', 
  apiUrl: 'https://mango-node-staging.herokuapp.com',
  // apiUrl: 'http://localhost:8080',
  fakeApiUrl: 'https://mango-node-staging.herokuapp.com/dtc-gateway-srvc/',
  logrocket: 'npobc9/d2c-staging',
  oktaConfig: {
    clientId: "0oa4iunald8h7tx801d7",
    issuer: "https://safeguard-uat-d2c.oktapreview.com/oauth2/aus4hrpxrmc6JZ4OD1d7",
    redirectUri: "https://staging.mygmcprotection.com",
    scopes: [
      "openid",
      "profile"
    ]
  },
  paypalDetails: {
    clientId: 'sb',
    currency: 'USD'
  }
};
