<section>
    <div class="view-contract pdf-iframe" *ngIf="pdf !== null">
        <pdf-viewer 
            [src]="src" 
            [render-text]="true" 
            [original-size]="false" 
            style="height: 100%">
        </pdf-viewer>
    </div>

    <div class="text-center mt-2 mb-2" *ngIf="pdf !== null">
        <a role="link" tabindex="0" class="blue-link uppercase" mdbWavesEffect id="download-contract-button"
            (click)="downloadPDF()">{{
            'MY_ACCOUNT_LOGIN.DOWNLOAD_CONTRACT' | translate }}
        </a>
    </div>


    <div class="parent" *ngIf="pdf === null">
        <div class="centered-div">
            No PDF Found
        </div>
    </div>
</section>
