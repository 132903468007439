import { createReducer, on } from '@ngrx/store';
import { create } from 'domain';
import { postServiceAgreement, postServiceAgreementFailed, postServiceAgreementSuccess, resetServiceAgreement } from '../_actions/pdf.actions';

export const initialState = null;


const _serviceAgreementReducer = createReducer(initialState,
    on(postServiceAgreement, (state, payload) => {
        console.log('Get Service Agreement PDF', state, payload)
        return payload;
    }),
    on(postServiceAgreementSuccess, (state, payload) => {
        console.log('Successfully retrieved  Service Agreement PDF', state, payload)
        return payload;
    }),
    on(postServiceAgreementFailed, (state, payload) => {
        console.log('Failed to retrieve  Service Plan PDF', state, payload)
        return payload;
    }),
    on(resetServiceAgreement, (state) => {
      console.log('Successfully reset state of Service Agreement PDF')

      return {...initialState};
  })
)

export function serviceAgreementReducer(state, action) {
    return _serviceAgreementReducer(state, action);
}
