import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { retry, catchError, map, sample } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { MakeResponse } from "../_models/make";
import { Model } from "../_models/model";
import { Trim } from "../_models/trim";
import brandInfo from "../../environments/brand.json";

import { GatewayService } from "./gateway.service";
import { LoggingService } from "./logging.service";
import { environment } from '../../environments/environment'


declare var gtag;

@Injectable({
  providedIn: "root",
})

export class DealerService {
  /**
   * Data Lookup Servie Methods
   *  1. Get Contract PDF
   *  2. Get Makes
   *  3. Get Models
   *  4. Get Trims
   *  5. Get Odometer Ranges (may not need) [not added yet]
   *  6. Get Model-Trim Combinations (may not need) [not added yet]
   *  7. Get Price (may not need) [not added yet]
   *  8. Get Vehicle
   *  9. Add Vehicle
   *  10. Update Vehicle
   *  11. Delete Vehicle
   */

  constructor(private httpClient: HttpClient, private gatewayService: GatewayService, private loggingService: LoggingService) {}

    // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };




validateDealerID(dealerId, customerId) {

  console.log('validateDealerID', dealerId, customerId);
  const tempUrl = 'http://localhost:8080'
//http://localhost:8080/v1/api/ppa/validateDealer/123/123
// ttp://localhost:8080/v1/api/validateDealer/123/123:

  // return this.httpClient.get(`${tempUrl}/v1/api/dealer/validateDealer/${dealerId}/${customerId}`)


  return this.httpClient.get(`${environment.apiUrl}/v1/api/dealer/validateDealer/${brandInfo.brand}/${dealerId}/${customerId}`)
  .pipe(
    retry(1),
    catchError(this.loggingService.handleError)
  )}
}
