import { Component, OnDestroy, OnInit } from '@angular/core';
import { PdfViewer } from '../core/pdfViewer.service';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import brandInfo from "src/environments/brand.json";
import { ViewContractRequest } from '../_models/pdf';
import { getContractByUuid } from '../_actions/pdf.actions';

@Component({
  selector: 'app-view-contract',
  templateUrl: './view-contract.component.html',
  styleUrls: ['./view-contract.component.scss']
})
export class ViewContractComponent implements OnInit, OnDestroy {
  pdf$: Observable<any>;
  pdfSub: Subscription;
  src: any;
  pdf: any;

  constructor(
    private pdfViewer: PdfViewer,
    private store: Store<{
      pdf: any
    }>,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    console.log('snapshots', this.route.snapshot.params)
    const uuid = this.route.snapshot.params['uuid'];
    const request: ViewContractRequest = {
      brandName: brandInfo.brand,
      uuid: uuid
    }

    this.store.dispatch(getContractByUuid(request))
    
    this.pdf$ = this.store.select((store) => store.pdf);
    this.pdfSub = this.pdf$.subscribe((pdf) => {
      console.log('pdf', pdf)
      if (pdf) {
        if (pdf.status && pdf.status === 'OK') {
          this.pdf = pdf.data.pdfRaw;
          console.log('pdf is not null')
          this.src = this.pdfViewer.createPdfViewer(pdf.data);
          // this.src = 'data:application/pdf;base64,' + pdf.data.pdfRaw
          console.log('src', this.src)
        } else {
          this.pdf = null
        }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.pdfSub) {
      this.pdfSub.unsubscribe();
    }
  }

  downloadPDF() {
    const downloadLink = document.createElement("a");
    downloadLink.href = 'data:application/pdf;base64,' + this.pdf;
    downloadLink.download = "Coverage Agreement.pdf";
    downloadLink.click();
  }

}
