import { createReducer, on } from '@ngrx/store';
import { create } from 'domain';

export const initialState = null;
import { getPDF, getPDFSuccess, getPDFFailed, downloadPDF, downloadPDFSuccess, downloadPDFFailed, storePrismicPDF, postPaymentPlanAgreement, postPaymentPlanAgreementSuccess, postPaymentPlanAgreementFailed, savePaymentPlanAgreementSignedPdf, getContractByUuid, getContractByUuidSuccess, getContractByUuidFailed} from '../_actions/pdf.actions';


const _pdfReducer = createReducer(initialState,
    on(getPDF, (state, payload) => {
        console.log('Get PDF', state, payload)
        return payload;
    }),
    on(getPDFSuccess, (state, payload) => {
        console.log('Successfully retrieved PDF', state, payload)
        return payload;
    }),
    on(getPDFFailed, (state, payload) => {
        console.log('Failed to get PDF', state, payload)
        return payload;
    }),
    on(downloadPDF, (state, payload) => {
        console.log('Get PDF', state, payload)
        return payload;
    }),
    on(downloadPDFSuccess, (state, payload) => {
        console.log('Successfully downloaded PDF', state, payload)
        return payload;
    }),
    on(downloadPDFFailed, (state, payload) => {
        console.log('Failed to download PDF', state, payload)
        return payload;
    }),
    on(postPaymentPlanAgreement, (state, payload) => {
        console.log('Get Payment Plan Agreement PDF', state, payload)
        return payload;
    }),
    on(postPaymentPlanAgreementSuccess, (state, payload) => {
        console.log('Successfully retrieved  Payment Plan Agreement PDF', state, payload)
        return payload;
    }),
    on(postPaymentPlanAgreementFailed, (state, payload) => {
        console.log('Failed to retrieve  Payment Plan Agreement PDF', state, payload)
        return payload;
    }),

    on(savePaymentPlanAgreementSignedPdf, (state, payload) => {
        console.log('Payment Plan agreement PDF', state, payload)
        return payload;
    }),
    on(getContractByUuid, (state, payload) => {
        return payload;
    }),
    on(getContractByUuidSuccess, (state, payload) => {
        return payload;
    }),

    on(getContractByUuidFailed, (state, payload) => {
        return payload;
    }),


);


export function pdfReducer(state, action) {
    return _pdfReducer(state, action);
}