export class PDFResponse {
    status: string;
    data: string;
    errorMessage: string;
}

export class PrismicPDF {
    pdf: string;
}


export class PaymentPlanAgreementPDF {
	docType: string;
	brand: string;
	customer: {
	  name: string;
	  address: {
		street: string;
		city: string;
		state: string;
		zip: string;
		}
		email: string;
		phone: string;
	  }
	  vehicle: {
		year: string;
		make: string;
		model: string;
		odometer: number;
		vin: string;
		term: number;
		mileage:string;
	  }
	  payment: {
		contractPrice: number;
		tax: number;
		downPayment:number;
		numberOfPayments: number;
		monthlyPaymentAmount: number;
		lastFourOfCC: string;
		expMonth: number;
		expYear: number;
		startDate: string;
		nameOnCard: string;
	}
}

export class ServiceAgreement {
    brand: string;
	customer: {
	  name: string;
	  address: {
		street: string;
		city: string;
		state: string;
		zip: string;
		}
		email: string;
		phone: string;
	  }
	  vehicle: {
		year: string;
		make: string;
		model: string;
		odometer: number;
		vin: string;
	  }
	  contract: {
		contractPrice: number;
        expDate: string;
	    term: number;
		mileage: number;
        plan: string;
        deductible: number;
        surcharges: string[];

	}
    
}

export class ViewContractRequest {
	brandName: string;
	uuid: string;
}

export class ViewContractResponse {
	status: string;
	data: any;
	errorMessage: string;
}