<div *ngFor="let field of slice.fields">

    <div [ngSwitch]="field?.section_type">
        <app-plan-select *ngSwitchCase="'price'" [plans]="plans" [_deductible]="deductible"
            (chosenPlan)="switchPlan($event)" (emitAddToCart)="addToCart($event)" (changeTerms)="getTerms($event)">
        </app-plan-select>
        <div *ngIf="thisBrand !== 'Hyundai'">
            <app-coverage-select *ngSwitchCase="'parts_checklist'" [plans]="plans" [chosenPlan]="chosenPlan"
                [_commercial_use_toggle]="commercial_use_toggle" [_snow_plow_toggle]="snow_plow_toggle"
                [_lift_kit_toggle]="lift_kit_toggle" [_grey_market_toggle]="grey_market_toggle"
                [changeTerms]="changeTerms" [_deductible]="deductible" [index]="closestTerm"
                (chosenTerm)="changeTerm($event)" (emitChangeTerms)="getTerms($event)"
                (emitAddToCart)="addToCart($event)" (emitUpdatePricing)="updatePricing($event)">
            </app-coverage-select>
        </div>
        <app-plan-pdf *ngSwitchCase="'sample_pdf'"></app-plan-pdf>
        <app-covered-components *ngSwitchCase="'term_length'" [plan]="chosenPlan"></app-covered-components>
        <app-coverage-overview *ngSwitchCase="'plans_includes'" [plan]="chosenPlan"></app-coverage-overview>
        <app-plans-overview *ngSwitchCase="'plans_overview'" [plan]="chosenPlan"></app-plans-overview>
        <div *ngIf="thisBrand !== 'Hyundai'">
            <app-mobile-plan-footer *ngSwitchCase="'price'" [plan]="chosenPlan"
                (emitAddToCart)="addToCart($event)"></app-mobile-plan-footer>
        </div>
    </div>

</div>
