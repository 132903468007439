import { Component, OnInit, OnDestroy, Output, Input, ViewChild, EventEmitter, OnChanges, AfterViewInit, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { VerifyAccountRequest, VerifyCodeResponse, VerifyAccountResponse, VerifyCodeRequest, VerifyAccountOktaFlowRequest } from 'src/app/_models/verify-account';
import { resetVerifiedAccount, verifyAccount, verifyAccountOktaFlow, verifyCode } from 'src/app/_actions/verify-account.actions';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { invalidCode, resetInvalidCodeState } from 'src/app/_actions/verify-code-state.actions';
import { Observable, Subscription } from 'rxjs';
import { AccountID, Paylink, Account, AccountEmail } from 'src/app/_models/account';
import { AppConfigService } from 'src/app/appconfig/appconfig.service';
import brandInfo from '../../../environments/brand.json'
import myOktaConfig from './../../okta-config'
import OktaSignIn from '@okta/okta-signin-widget';
import { OktaAuth } from '@okta/okta-auth-js';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ModalOktaErrorComponent } from 'src/app/modal-okta-error/modal-okta-error.component';
import { checkRegistrationToken, oktaPasswordReset, oktaSignIn, registerOktaUserSuccess, setOktaPassword } from 'src/app/_actions/okta.actions';
import { OktaRegistrationToken, OktaSetPasswordRequest, OktaUser } from 'src/app/_models/okta';
import { hideInlineLoader, showInlineLoader } from 'src/app/_actions/inline-loader.actions';
import { OktaService } from '../../_services/okta.service';
import { getAccountSFID, resetMyAccount } from 'src/app/_actions/account.actions';

@Component({
  selector: 'app-my-account-verify',
  templateUrl: './my-account-verify.component.html',
  styleUrls: ['./my-account-verify.component.scss']
})
export class MyAccountVerifyComponent implements OnInit, AfterViewInit, OnDestroy {
  // For ngSwitch - Prod gets hung up on an undefined view property
  emailReset: boolean
  phoneReset: boolean
  view: string;
  verifyCodeRequest: VerifyCodeRequest
  verifyCodeState$: Observable<any>;
  branding: any;
  paylink$: Observable<any>;
  account$: Observable<any>;
  session$: Observable<any>;
  inlineLoader$: Observable<any>;

  code$: Observable<any>;
  verifyType: string;
  verifyAccount$: Observable<any>;
  oktaSignIn: any
  oktaAuth: any
  useOkta: boolean;
  showOktaPasswordFlow = false;
  showSpinner = true;
  @ViewChild('widgetContainer') widgetContainer: ElementRef;
  changePasswordForm: UntypedFormGroup;
  orderNumberForm: UntypedFormGroup;
  passwordFlow: Number;
  modalRef: MDBModalRef;
  oktaId: any;
  registrationToken: string;
  email: any;
  orderNumberError = false;
  oktaUsername: string;
  oktaPasswordErrorReason: string

  constructor(
    private store: Store<{
      inlineLoader: any,
      verifyCodeState: any,
      session: any,
      paylink: Paylink,
      account: any,
      verifyCode: any,
      verifyAccount: any,
      oktaRegistration: any,
      oktaPassword: any,
      oktaUser
    }>,
    private appConfig: AppConfigService,
    private el: ElementRef,
    private modalService: MDBModalService,
    private route: ActivatedRoute,
    private oktaService: OktaService
  ) {
    this.oktaAuth = new OktaAuth(myOktaConfig.oidc)
  }

  ngOnInit() {
    this.showOktaPasswordFlow = false
    this.store.dispatch(resetVerifiedAccount())
    this.appConfig.getSettings().subscribe((result) => {
      this.branding = result;
    });

    this.useOkta = brandInfo.okta;

    this.orderNumberForm =new UntypedFormGroup({
      orderNumber: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
      lastName: new UntypedFormControl(null, { validators: [Validators.required], updateOn: 'blur' })
    })

    this.route.queryParams.subscribe(params => {
      console.log('Params', params)

      this.changePasswordForm = new UntypedFormGroup({
        newPassword: new UntypedFormControl(null, {
          validators: [
            Validators.required,
            Validators.minLength(12),
            (control => this.checkPasswordForUsername(control)),
            Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")
          ],
          updateOn: 'blur'
        }),
        confirmNewPassword: new UntypedFormControl(null, {
          validators: [Validators.required, (control => this.confirmPassword(control, this.changePasswordForm, 'newPassword'))],
          updateOn: 'blur'
        })
      })

      let object: OktaRegistrationToken = {
        token: params["registrationtoken"] || ""
      }

      console.log(object)

      if (object.token !== "") {
        const _oktaUser: OktaUser = JSON.parse(sessionStorage.getItem('oktaUser'))
        this.registrationToken = object.token
        this.store.dispatch(checkRegistrationToken(object))
        this.store.dispatch(showInlineLoader())
        let oktaRegistrationSub = this.store.select(store => store.oktaRegistration).subscribe(data => {
          console.log('oktaRegistration', data)
          if (data && data === 'password') {
            console.log('_oktaUser', _oktaUser)
            this.passwordFlow = 3
            this.showOktaPasswordFlow = true;
            this.oktaId = _oktaUser.id
            this.email = _oktaUser.login
            this.store.dispatch(hideInlineLoader())
          } else if (data && data === false) {
            this.oktaPasswordErrorReason = "Password Requirements Not Met";
            this.store.dispatch(hideInlineLoader())
          }else {
            this.showWidget()
            this.store.dispatch(hideInlineLoader())
          }
        })
      }

      let accountSub = this.store.select(store => store.account).subscribe(data => {
        console.log('Account Sub', data)
        if (data && data.d2cAccountId) {
          this.store.dispatch({ type: '[My Account Component] Load Account', payload: data.d2cAccountId})
          this.store.dispatch(resetMyAccount())
        }
      })



      let oktaPasswordSub = this.store.select(store => store.oktaPassword).subscribe(data => {
        console.log('oktaPassword', data)
        if (data && data === true) {
          this.showWidget()
          this.store.dispatch(oktaPasswordReset())
          this.store.dispatch(hideInlineLoader())
        } else if (data && data === false) {
          this.oktaPasswordErrorReason = "Password Requirements Not Met";
          this.store.dispatch(hideInlineLoader())
        }
      })

      let verifyAccountSub = this.store.select(store => store.verifyAccount).subscribe(data => {
        console.log('verifyAccountSub', data)
        if (data && data.id && brandInfo.okta) {
          this.oktaId = data.id
          this.passwordFlow = 2;
          this.orderNumberError = false;
          this.store.dispatch(resetVerifiedAccount())
        } else if (data && data === 'No Okta Account Found') {
          this.orderNumberError = true;
        }
      })

    })
  }

  ngAfterViewInit() {
    setTimeout(() => {  // SetTimeout needed to reload okta widget correctly if user clicks 'My Account' while on my account page
      if (this.useOkta) {
        this.oktaSignIn = new OktaSignIn({
          logo: '',
          features: {
            registration: false,
            scrollOnError: false
          },
          baseUrl: myOktaConfig.oidc.issuer.split('/oauth2')[0],
          clientId: myOktaConfig.oidc.clientId,
          redirectUri: myOktaConfig.oidc.redirectUri,
          useInteractionCodeFlow: true,
          authParams: {
            pkce: true,
            issuer: myOktaConfig.oidc.issuer,
            scopes: myOktaConfig.oidc.scopes
          },
          transformUsername: (username, operation) => {
            // This example will append the '@acme.com' domain if the user has
            // not entered it
            this.oktaUsername = username;
            window.sessionStorage.setItem('oktaUser', this.oktaUsername);
            return username + brandInfo.id
          },
        })

        this.oktaSignIn.showSignIn({
          el: '#widget-container'
        }).then(tokens => {
          console.log('Tokens', tokens)
          const accessToken = tokens.tokens.accessToken;
          const idToken = tokens.tokens.idToken
          let object: OktaUser = {
            id: idToken.claims.sub,
            login: idToken.claims.preferred_username,
            access_token: accessToken.accessToken
          }
          console.log('SFID', accessToken.claims.sfid)
          this.showWidget()
          this.store.dispatch(oktaSignIn(object))

          let request: AccountEmail = {
            email: idToken.claims.preferred_username.substr(0, idToken.claims.preferred_username.length - 6)
          }

          this.store.dispatch(getAccountSFID(request))
        }).catch(error => {
          console.log('Error from Okta', error)
        })

        this.oktaSignIn.on('afterRender', () => {
          this.showSpinner = false;
          console.log('widget finished')
          this.checkForReset();
          this.checkForMethod();
          this.checkForAuthMethod();
          this.addSignInVerbiage();
          this.checkForPasswordLength();
        })

      }
    })

  }

  checkForAuthMethod() {
    let list = this.widgetContainer.nativeElement.querySelector('.authenticator-enroll-list');
    if(list) {
      let elements = this.widgetContainer.nativeElement.querySelectorAll('.authenticator-row');
      let password = document.createElement('i');
      password.classList.add("fa");
      password.classList.add("fa-id-badge");
      password.style.fontSize = "40px";
      elements[0].prepend(password);
      let phone = document.createElement('i');
      phone.classList.add("fa");
      phone.classList.add("fa-mobile");
      phone.style.fontSize = "40px";
      elements[1].prepend(phone);
      elements[0].parentNode.insertBefore(elements[1], elements[0]);
    }
  }

  addSignInVerbiage() {
    const elements = this.widgetContainer.nativeElement.querySelectorAll('h2');
    if (elements && elements[0].textContent === 'Sign In') {
      elements[0].insertAdjacentHTML('afterend', '<p class="">Username is the email address you used at purchase.</p>');
    }
  }


  checkForMethod() {
    let list = this.widgetContainer.nativeElement.querySelector('.authenticator-verify-list');
    if (list) {
      let elements = this.widgetContainer.nativeElement.querySelectorAll('.authenticator-description');
      let password = document.createElement('i');
      password.classList.add("fa");
      password.classList.add("fa-lock");
      password.style.fontSize = "40px";
      elements[0].prepend(password);
      let phone = document.createElement('i');
      phone.classList.add("fa");
      phone.classList.add("fa-phone-square");
      phone.style.fontSize = "40px";
      elements[1].prepend(phone);
    }
  }

  checkForReset() {
    let passwordLink = this.widgetContainer.nativeElement.querySelector('.js-forgot-password');
    let footer = this.widgetContainer.nativeElement.querySelector('.auth-footer');
    if (passwordLink) {
      passwordLink.remove();
      let newPasswordLink = document.createElement('a');
      newPasswordLink.innerHTML = 'Forgot Password';
      newPasswordLink.href = "#";
      newPasswordLink.classList.add('link');
      passwordLink.id = "passwordLink";
      footer.prepend(newPasswordLink);
      newPasswordLink.addEventListener('click', this.showPassword.bind(this))
    }
  }

  checkForPasswordLength() {
    let passwordInputs = this.widgetContainer.nativeElement.querySelectorAll("input[type='password']");
    passwordInputs.forEach(input => {
      input.minLength = 12;
    })
  }

  confirmPassword(control: AbstractControl, group: UntypedFormGroup, matchPassword: string) {
    if (control.value && group && group.controls[matchPassword].value !== control.value) {
      return { 'mismatch': true };
    }
    return null
  }

  checkPasswordForUsername(control: AbstractControl) {
    if (control.value && !this.oktaService.no_username_in_password(control.value, this.email)) {
      return { 'username': true };
    }
    return null
  }

  showPassword(e) {
    e.preventDefault();
    this.passwordFlow = 1;
    this.showOktaPasswordFlow = true;
    this.orderNumberError = false;
    this.changePasswordForm.reset();
    this.orderNumberForm.reset();
  }

  checkOrderNummber(form) {
    console.log('Form', form)
    const object: VerifyAccountOktaFlowRequest = {
      orderNumber: form.value.orderNumber,
      lastName: form.value.lastName,
    }

    this.store.dispatch(verifyAccountOktaFlow(object))
  }

  setPassword() {
    const _oktaUser: OktaUser = JSON.parse(sessionStorage.getItem('oktaUser'))
    let object: OktaSetPasswordRequest = {
      password: this.changePasswordForm.controls['newPassword'].value,
      id: _oktaUser.id,
      token: this.registrationToken,
      email: _oktaUser.login
    }

    this.store.dispatch(showInlineLoader())
    this.store.dispatch(setOktaPassword(object))
  }

  showWidget() {
    this.showOktaPasswordFlow = false;
    this.passwordFlow = 1;
  }

  ngOnDestroy() {
    if (this.oktaSignIn) {
      this.oktaSignIn.remove();
    }
  }

  viewEmitterFunction(data) {
    console.log('Parent Component - View Emitter', data)
    this.view = data
  }

  verifyTypeEmitterFunction(data) {
    console.log('Parent Component - Verify Type Emitter', data)
    this.verifyType = data
  }

  verifyCodeReuqestEmitterFunction(data) {
    console.log('Parent Component - Verify Code Request Emitter', data)
    this.verifyCodeRequest = data
  }

  resetEmitterFunction(data) {
    console.log('Parent Component - Reset Emitter', data)
    if (data === true) {
      this.phoneReset = true
      this.emailReset = true
    }
  }
}
