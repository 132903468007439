import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import * as ActionConfigurationManager from '../_actions/config.actions';
import { Action, Store } from '@ngrx/store';
import { ProspectService } from '../_services/prospect-service.service';
import { cartSavedSuccess, updateCartSuccess, getCartSuccess } from '../_actions/cart.actions';
import { Cart, LineItems } from '../_models/cart';
import { hideInlineLoader } from '../_actions/inline-loader.actions';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { OrderAccountFlow } from '../_models/order';
import { hideOrderStateInlineLoader, submitOrderAccountFlow, submitOrderFailed } from '../_actions/order.actions';
import { throwError } from 'rxjs/internal/observable/throwError';
import { removeToken } from '../_actions/token.actions';
import brandInfo from "src/environments/brand.json";
import { Opportunity } from '../_models/account';
import { PurchaseContractTokenRefresh } from '../_models/token-refresh';
import { purchaseContractTokenRefresh } from '../_actions/token-refresh.actions';

@Injectable()

export class CartEffects {

    public saveCart$: Observable<Action> = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ActionConfigurationManager.CART_DB),
                switchMap((action: any) => this.cartService.submit_cart(action)
                    .pipe(
                        map((cartResponse: any) => {
                            console.log(cartResponse);
                            console.log(action);

                            let cart: Cart = JSON.parse(sessionStorage.getItem('cart'))
                            cart.shoppingCartId = cartResponse.data.shoppingCartExternalId
                            console.log(cart);
                            this.store.dispatch(cartSavedSuccess(cart))
                            return hideInlineLoader();
                        }),
                        catchError(() => EMPTY)
                    )
                )
            )
    )

    public getCart$: Observable<Action> = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ActionConfigurationManager.GET_CART),
                switchMap((action: any) => this.cartService.get_cart(action)
                    .pipe(
                        map((cartResponse: any) => {
                            console.log(cartResponse)
                            let cart: Cart = {
                                shoppingCartId: cartResponse.data.shoppingCartExternalId,
                                commercialUse: false,
                                liftKit: false,
                                snowPlow: false,
                                greyMarket: false,
                                subtotal: 0,
                                estimatedTax: 0,
                                deductible: 0,
                                isDisappearing: false,
                                grandTotal: cartResponse.data.price,
                                numberOfMonthlyPayments: 0,
                                monthlyPayment: 0,
                                downPayment: 0,
                                downPaymentWithTax: 0,
                                plans: [],
                                shoppingCartData: {
                                    accountExternalId: '',
                                    accountSfId: '',
                                    brandExternalId: '',
                                    brandSfId: '',
                                    contactExternalId: '',
                                    contactSfId: '',
                                    shoppingCartReferenceNumber1: cartResponse.data.referenceNumber1,
                                    shoppingCartReferenceNumber2: cartResponse.data.referenceNumber2,
                                    shoppingCartReferenceNumber3: cartResponse.data.referenceNumber3,
                                    shoppingCartReferenceNumber4: cartResponse.data.referenceNumber4,
                                    status: '',
                                    vehicleExternalId: '',
                                    vehicleSfId: '',
                                    lineItems: [],
                                }
                            }

                            for (let i = 0; i < cartResponse.data.shoppingCartLineItems.length; i++) {
                                let lineItems: LineItems = {
                                    currency: brandInfo.isoCode,
                                    shoppingCartLineItemReferenceNumber1: cartResponse.data.shoppingCartLineItems[i].referenceNumber1,
                                    shoppingCartLineItemReferenceNumber2: cartResponse.data.shoppingCartLineItems[i].referenceNumber2,
                                    shoppingCartLineItemReferenceNumber3: cartResponse.data.shoppingCartLineItems[i].referenceNumber3,
                                    shoppingCartLineItemReferenceNumber4: cartResponse.data.shoppingCartLineItems[i].referenceNumber4,
                                    shoppingCartLineItemId: cartResponse.data.shoppingCartLineItems[i].shoppingCartLineItemExternalId,
                                    itemDescription: cartResponse.data.shoppingCartLineItems[i].itemDescription,
                                    itemName: cartResponse.data.shoppingCartLineItems[i].itemName,
                                    itemInCartFrom: cartResponse.data.shoppingCartLineItems[i].itemInCartFrom,
                                    lineItemCostExcludingTax: cartResponse.data.shoppingCartLineItems[i].lineItemCostExcludingTax,
                                    lineItemCostIncludingTax: cartResponse.data.shoppingCartLineItems[i].lineItemCostIncludingTax,
                                    status: cartResponse.data.shoppingCartLineItems[i].status,
                                    salesTax: cartResponse.data.shoppingCartLineItems[i].salesTax,
                                    product2ExternalId: cartResponse.data.shoppingCartLineItems[i].product2ExternalId,
                                    product2Sfid: cartResponse.data.shoppingCartLineItems[i].product2Sfid,
                                    productSkuExternalId: cartResponse.data.shoppingCartLineItems[i].productSkuExternalId,
                                    productSkuSfId: cartResponse.data.shoppingCartLineItems[i].productSkuSfId
                                }
                                cart.shoppingCartData.lineItems.push(lineItems)
                            }

                            console.log(cart)
                            return getCartSuccess(cart)
                        }),
                        catchError(() => EMPTY)
                    )
                )
            )
    )

    public updateCart$: Observable<Action> = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ActionConfigurationManager.UPDATE_CART),
                switchMap((action: any) => this.cartService.update_cart(action)
                    .pipe(
                        map((cartResponse: any) => {
                            console.log(cartResponse)
                            let cart: Cart = JSON.parse(sessionStorage.getItem('cart'))
                            console.log('Cart after update', cart);
                            this.store.dispatch(updateCartSuccess(cart))
                            return hideInlineLoader();
                        }),
                        catchError(() => EMPTY)
                    )
                )
            )
    )

    public prepCart$: Observable<Action> = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ActionConfigurationManager.PREP_CART_FOR_ORDER),
                switchMap((action: any) => this.cartService.update_cart(action.cartData)
                    .pipe(
                        map((cartResponse: any) => {
                            console.log('Cart after update - pay now', action.cartData);
                            this.store.dispatch(updateCartSuccess(action.cartData))

                            // let dataObject: OrderAccountFlow = action.purchaseContractRequest

                            let dataObject: OrderAccountFlow = {
                                contract: [],
                                createAccount: {
                                    opportunityId: '',
                                    brandSfId: '',
                                    purchaseRecordId: '',
                                    brandName: brandInfo.brand,
                                    customer: {
                                        accountSfId: '',
                                        firstName: '',
                                        lastName: '',
                                        city: '',
                                        state: '',
                                        zipCode: '',
                                        phoneNumber: '',
                                        emailId: '',
                                        street: '',
                                        country: ''
                                    },
                                    vehicle: {
                                        year: '',
                                        make: '',
                                        model: '',
                                        vin: '',
                                        trim: '',
                                        odometer: '',
                                        vehicleSfId: ''
                                    }
                                },
                                order: {
                                    accountSfId: '',
                                    accountcontactSfId: '',
                                    brandSfId: '',
                                    contactSfId: '',
                                    currency: brandInfo.isoCode,
                                    emailId: '',
                                    shoppingCartId: '',
                                    taxBreakDownExternalId: '',
                                    termsNCondition: true,
                                    vehicleSfId: '',
                                    employeeId: '',
                                    dealerId: ''
                                },
                                payment: {
                                    brandName: '',
                                    monthlyPayment: 0,
                                    salesTax: '',
                                    billingAddress: '',
                                    billingCity: '',
                                    billingName: '',
                                    billingPostalCode: '',
                                    billingState: '',
                                    ccExpMonth: '',
                                    ccExpYear: '',
                                    ccNum: '',
                                    cvcValue: '',
                                    ccNameOnCard: '',
                                    totalPremium: 0,
                                    coverageMileage: 0,
                                    coveragePlanName: '',
                                    coverageProgram: '',
                                    coverageTerm: 0,
                                    coverageType: '',
                                    downPayment: 0,
                                    effectiveDate: '',
                                    firstPmtDate: '',
                                    numOfTotalPmts: 0,
                                    odometer: 0,
                                    purchaserAddress1: '',
                                    purchaserAddress2: '',
                                    purchaserCity: '',
                                    purchaserEmail: '',
                                    purchaserName: '',
                                    purchaserPhone: '',
                                    purchaserState: '',
                                    purchaserZip: '',
                                    vehicleMake: '',
                                    vehicleModel: '',
                                    vehicleYear: 0,
                                    vinNum: '',
                                    taxService: ''
                                }
                            }

                            Object.assign(dataObject, JSON.parse(JSON.stringify(action.purchaseContractRequest)))

                            console.log('purchase contract object', dataObject)
                            console.log('cart response', cartResponse)

                            // for (let i = 0; i < dataObject.contract.length; i++) {
                            //     for (let j = 0; j < cartResponse.data.shoppingCartLineItems.length; j++) {
                            //         if (dataObject.contract[i].productCode === cartResponse.data.shoppingCartLineItems[j].productCode) {
                            //             dataObject.contract[i].shoppingCartLineItemId = cartResponse.data.shoppingCartLineItems[j].shoppingCartLineItemExternalId
                            //         }
                            //     }
                            // }

                            return submitOrderAccountFlow(dataObject);
                        }),
                        catchError((error) => {
                            console.log('error purchasecontract', error)
                            if (error.includes('401 Unauthorized')) {
                                let opp: Opportunity = JSON.parse(sessionStorage.getItem('opp'))
                                let request: PurchaseContractTokenRefresh = {
                                    opportunityId: opp.opportunityId,
                                    cartData: action.cartData,
                                    purchaseContractRequest: action.purchaseContractRequest
                                }
                                this.store.dispatch(removeToken())
                                this.store.dispatch(purchaseContractTokenRefresh(request))
                            } else {
                                this.store.dispatch(hideOrderStateInlineLoader())
                                this.store.dispatch(submitOrderFailed())
                                return throwError(error)
                            }
                        })
                    )
                )
            )
    )

    constructor(
        private actions$: Actions,
        private cartService: ProspectService,
        private store: Store<{}>,
        private router: Router
    ) { }
}
