import { Component, OnInit, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { HomeService } from "./../_services/home.service";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { Cart, PlanTerms } from "../_models/cart";
import { Vehicle } from "../_models/vehicle";
import { Registration } from "../_models/registration";
import { Account } from "../_models/account";
import brandInfo from "../../environments/brand.json";
import { environment } from "../../environments/environment";
import { TaxResponse } from "../_models/tax";
import { AppConfigService } from "../appconfig/appconfig.service";
import { resetMyAccount, updateBillingStatOff } from "../_actions/account.actions";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  selectedPlan = sessionStorage.getItem("selectedPlan");
  termYears = sessionStorage.getItem("termYears");
  termMileage = sessionStorage.getItem("termMileage");
  termTotalPrice = sessionStorage.getItem("termTotalPrice");
  numOfMonthlyPayments = sessionStorage.getItem("numOfMonthlyPayments");
  monthlyPayments = sessionStorage.getItem("monthlyPayments");
  subtotal = sessionStorage.getItem("subtotal");
  ppmActive = "false";
  roadsideActive = "false";

  totalWithTax: any;
  calculatedTax: any;
  finalMonthlyPayments: any;
  logo: any;
  branding: any;
  isLogin = false;
  isNavVisible = true;
  isWidget = false;

  public cart$: Observable<Cart>;
  public vehicle$: Observable<Vehicle>;
  public registration$: Observable<Registration>;
  addons$ = [];
  isCart: boolean = false;
  cartSubscription: Subscription;
  environment: boolean;
  appFlowState$: Observable<any>;
  tax$: Observable<any>;
  currentBrand: string;
  currentDate: Date;
  @ViewChild('cartModal') cartModal;
  navLinks: [];

  constructor(
    private el: ElementRef,
    private router: Router,
    private home: HomeService,
    private appConfig: AppConfigService,
    private location:Location,
    private store: Store<{
      appFlowState: any;
      vehicle: Vehicle;
      cart: Cart;
      registration: Registration;
      tax: TaxResponse;
      account: Account;
    }>
  ) {}

  ngOnInit() {
    this.appConfig.getSettings().subscribe(
      (result) => {
        this.branding = result;
      },
      (error) => {
        this.isNavVisible = false;
      }
    );

    this.store
      .select((store) => store.account)
      .subscribe((account) => {
        if (account) {
          this.isLogin = true;
        }
      });

    this.appFlowState$ = this.store.select((store) => store.appFlowState);

    this.vehicle$ = this.store.select((store) => store.vehicle);
    this.cart$ = this.store.select((store) => store.cart);
    this.registration$ = this.store.select((store) => store.registration);
    this.tax$ = this.store.select((store) => store.tax);
    // console.log(this.cart$);

    this.currentBrand = brandInfo.brand;

    this.home.getHeader(brandInfo.brand, sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language).subscribe({
      next: (result) => {
        this.logo = result.logo;
        if (result.nav_links) {
          this.navLinks = result.nav_links;
        }
      }, error: (err) => {
        this.isNavVisible = false;
        console.error("Error getting Prismic Header.", this.isNavVisible)
      }
    });

    // If widget, hide nav
    if (this.location.path().includes("widget")) {
      this.isWidget = true;
    }

    let checker = this.subtotal;
  }

  ngOnDestroy() {
    this.cartSubscription.unsubscribe();
  }

  // getEstimatedTax(subtotal, taxAmount) {
  //   this.calculatedTax = (parseFloat(subtotal) * parseFloat(taxAmount)).toFixed(
  //     2
  //   );
  //   this.totalWithTax = (
  //     parseFloat(this.subtotal) + parseFloat(this.calculatedTax)
  //   ).toFixed(2);
  //   this.finalMonthlyPayments = (
  //     parseFloat(this.totalWithTax) / parseFloat(this.numOfMonthlyPayments)
  //   ).toFixed(2);
  // }

  // goToCart(cart) {
  //   this.router.navigateByUrl("cart");
  //   cart.hide();
  //   return false;
  // }

  clickAccountLogout() {
    this.closeNav();
    this.store.dispatch(updateBillingStatOff());

    if (this.isLogin) {
      this.store.dispatch(resetMyAccount());
      this.isLogin = false;

      if (this.router.url === "/my-account") {
        this.router.navigate(["verify-account"]);
      }
    } else {
      this.router.navigate(["verify-account"]);
    }
  }

  showCart() {
    console.log('showCart');
    // this.cartModal.show();
    this.router.navigate(["cart"]);
  }

  openNav() {
    document.getElementById("mySidenav").style.display = "block";
    setTimeout(() => {
      document.getElementById("mySidenav").style.marginRight = "0";
    }, 0);
  }

  closeNav() {
    document.getElementById("mySidenav").style.marginRight = "-300px";
    setTimeout(() => {
      document.getElementById("mySidenav").style.display = "none";
    }, 400);
  }

  productHome() {
    console.log("Clicked productHome from nav bar.");
  }

  cmsSandbox() {
    console.log("Clicked cms-sandbox from nav bar.");
  }

  uiSandbox() {
    console.log("Clicked ui-sandbox from nav bar.");
  }

}
